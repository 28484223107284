import React, { useContext } from "react";

import {
  Grid,
  Box,
  Container,
  Typography,
  makeStyles,
  ListItem,
  List,
  Link,
  IconButton,
} from "@material-ui/core";
// import { UserContext } from 'src/context/User'
import SettingsContext from "src/context/SettingsContext";
import { useHistory, Link as RouterLink } from "react-router-dom";
import { AiOutlineMail } from "react-icons/ai";
import {
  FaInstagram,
  FaTelegramPlane,
  FaDiscord,
  FaYoutube,
  FaLinkedin,
} from "react-icons/fa";

import { AiOutlineReddit } from "react-icons/ai";
import Scroll from "react-scroll";
import { useWeb3React } from "@web3-react/core";
const useStyles = makeStyles((theme) => ({
  footerSection: {
    // background: theme.palette.background.card,
    position: "relative",
    padding: "50px 0px 0",
    zIndex: "2",
    overflow: " hidden",
    background: "rgba(255, 255, 255, 0.02)",
    "& .footerContentBox": {
      maxWidth: "340px",
      [theme.breakpoints.down("xs")]: {
        maxWidth: "100%",
      },
    },
    "& .copy": {
      borderTop: "1px solid #d0d0d017",
      padding: "10px 0",
      textAlign: "center",
      fontWeight: 300,
      fontSize: "12px",
      color: "#626262",
      "& p": {
        "& span": {
          background:
            "linear-gradient(93.34deg, #FF6F37 6.82%, #FF2676 35.9%, #B801AA 68.08%, #7101BC 101.4%)",

          backgroundClip: "text",

          WebkitBackgroundClip: "text",
          WebkitTextFillColor: "transparent",
        },
      },
    },
    "& ul": {
      paddingLeft: "0",
      "& li": {
        paddingLeft: "0",
        alignItems: "center",
        color: theme.palette.text.gray,
        fontSize: "14px",
        fontWeight: "300",
        "& svg": {
          marginRight: "10px",
          color: "#fe2efe",
          fontSize: "15px",
        },
      },
    },
    "& svg": {
      color: "rgba(255 255 255 / 30%)",
      fontSize: "15px",
    },
    "& p": {
      color: theme.palette.text.gray,
    },
    "& h6": {
      [theme.breakpoints.down("sm")]: {
        marginTop: "30px",
      },
      [theme.breakpoints.down("xs")]: {
        marginTop: "10px",
      },
    },

    "& a": {
      color: theme.palette.text.gray,
      fontWeight: 300,
      textDecoration: "none",
      fontSize: "12px",
      display: "flex",
      alignItems: "center",
      paddingLeft: "0px",
      paddingRight: "0px",
      [theme.breakpoints.only("xs")]: {
        fontSize: "11px",
      },
      "& :hover": {
        color: "##fe2efe",

        textDecoration: "none",
        "& svg": {
          color: "red",
          fontSize: "15px",
        },
      },
    },
    "& .borderBox": {
      position: "absolute",
      left: "153px",
      top: "12px",
      [theme.breakpoints.down("sm")]: {
        display: "none",
      },
    },
  },
  iconbtn: {
    "& .MuiIconButton-root": {
      background: theme.palette.background.blur,
      marginRight: "8px",
      marginBottom: "8px",
      background: "rgba(255, 255, 255, 0.05)",
      "& :hover": {
        "& svg": {
          color: "#fe2efe",
          fontSize: "15px",
        },
      },
    },
  },
}));

export default function Footer() {
  const classes = useStyles();
  const history = useHistory();
  const themeSeeting = useContext(SettingsContext);
  // const user = useContext(UserContext)
  const { account, library, chainId } = useWeb3React();

  return (
    <>
      <Box className={classes.footerSection}>
        <Container maxWidth="lg">
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12} md={5}>
              <Box style={{}} className="footerContentBox">
                <Box mb={2}>
                  {" "}
                  <RouterLink to="/">
                    <img
                      src="/images/logo.png"
                      alt=""
                      style={{ width: "152px" }}
                    />{" "}
                    <br />
                  </RouterLink>
                </Box>
                <Typography variant="body1">
                  Fieres is an Ethereum Virtual Machine (EVM) compatible
                  blockchain constructed using a dependable and safe
                  Proof-of-Authority (PoA) consensus algorithm. The Fieres
                  blockchain ecosystem delivers unparalleled speed and minimal
                  transaction fees while ensuring the highest level of security
                  and scalability.
                </Typography>
              </Box>
            </Grid>

            <Grid item xs={6} sm={3} md={4}>
              <Typography variant="h6" color="primary">
                Quick Links
              </Typography>
              <List>
                <ListItem to="/" component={RouterLink}>
                  Home
                </ListItem>
                {/* <ListItem to="/about" component={RouterLink}>
                  Docs
                </ListItem> */}

                <ListItem to="/about" component={RouterLink}>
                  About Us
                </ListItem>
                <a
                  target="_blank"
                  href="images/Fieres_Whitepaper.pdf"
                  style={{ cursor: "pointer", marginTop: "6px" }}
                >
                  White Paper
                </a>
              </List>
            </Grid>
            <Grid item xs={6} sm={3} md={3}>
              {/* <Typography variant="h6" color="primary">
                Contact Us
              </Typography> */}
              {/* <List>
                <ListItem>
                  <Link href="mailto:info@fierex.site">
                   
                    Contact@fieres.com
                  </Link>
                </ListItem>
              </List> */}
              <Box className={classes.iconbtn}>
                <IconButton>
                  <Link
                    target="_blank"
                    href="https://www.instagram.com/fieresofficial/?igshid=YmMyMTA2M2Y%3D"
                  >
                    <FaInstagram />
                  </Link>
                </IconButton>
                <IconButton>
                  <Link target="_blank" href="https://t.me/+YEuUrv0Hw0s0NmE0">
                    <FaTelegramPlane />
                  </Link>
                </IconButton>
                {/* <IconButton>
                  <Link target="_blank" href="https://discord.com/">
                    <FaDiscord />
                  </Link>
                </IconButton>
                <IconButton>
                  <Link target="_blank" href="https://www.youtube.com">
                    <FaYoutube />
                  </Link>
                </IconButton>
                <IconButton>
                  <Link target="_blank" href="https://www.linkedin.com/">
                    <FaLinkedin />
                  </Link>
                </IconButton> */}
              </Box>
            </Grid>
          </Grid>
        </Container>
        <Box className="copy" mt={1}>
          <Container>
            <Box
              alignItems="center"
              my={2}
              position="relative"
              flexWrap="wrap"
              display="flex"
              justifyContent="space-between"
            >
              <Typography variant="body1" style={{ fontWeight: "200" }}>
                © 2025 Fieres. All Rights Reserved
              </Typography>

              <Box className="displayStart">
                {/* <img
                  src="images/footer_line.png"
                  alt="images"
                  className="borderBox"
                /> */}
                <List className="displayStart">
                  <ListItem
                    to="/privacy-policy"
                    component={RouterLink}
                    style={{ whiteSpace: "pre" }}
                  >
                    Privacy Policy
                  </ListItem>

                  {/* <ListItem
                    to="/terms-conditions"
                    component={RouterLink}
                    style={{ marginLeft: "28px", whiteSpace: "pre" }}
                  >
                    Terms & Conditions
                  </ListItem> */}
                  <ListItem
                    to="/token-disclaimer"
                    component={RouterLink}
                    style={{ marginLeft: "28px", whiteSpace: "pre" }}
                  >
                    Token Disclaimer
                  </ListItem>
                </List>
              </Box>
            </Box>
          </Container>
        </Box>
      </Box>
    </>
  );
}
